<template>
  <div class="success-wrapper" :class="type === 'warning' && 'wraning'">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '咨询成功'
    },
    type: {
      type: String,
      default: 'success'
    }
  }
}
</script>

<style lang="scss" scoped>
.success-wrapper {
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  color: #0CBE65;

  &.wraning {
    color: #F9B11D !important;
  }
}
</style>