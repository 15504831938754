import Cookies from "js-cookie";
const cookie = 'cookiey-user';

// 设置cookie
export function setCookie(value) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + (8 * 60 * 60 * 1000)); // 将当前时间加上8小时的毫秒数
  Cookies.set(cookie, value, { expires: expirationDate });
}

// 获取cookie
export function getCookie() {
  return Cookies.get(cookie);
}

// 删除cookie
export function removeCookie() {
  Cookies.remove(cookie);
}