<template>
  <div class="layout-container">
    <SuccessTips v-if="showTips" :type="type" :title="tipMsg" />
    <HeaderView />
    <div class="banner-wrap">
      <div class="banner-box">
        <img src="@/assets/images/banner-1.png" alt="">
        <div class="consult-box">
          <div class="title_color size24 size_b flex flex_center">在线咨询</div>
          <template>
            <input v-model="form.contacterName" class="border ipt" :readonly="isCookie && isEdit"
              :class="[(isCookie && isEdit) && 'readonly']" placeholder="您的姓名" type="text">
            <input v-model="form.contacterMobile" class="border ipt" :readonly="isCookie && isEdit"
              :class="[(isCookie && isEdit) && 'readonly']" placeholder="手机号码" type="text">
            <input v-model="form.organizationName" class="border ipt" :readonly="isCookie && isEdit"
              :class="[(isCookie && isEdit) && 'readonly']" placeholder="公司名称" type="text">
            <textarea v-model="form.contacterContent" class="border ipt" :readonly="isCookie && isEdit"
              :class="[(isCookie && isEdit) && 'readonly']" placeholder="咨询内容" rows="4"></textarea>
            <template v-if="isEdit">
              <button class="btn" @click="isEdit = false">编辑</button>
            </template>
            <template v-else>
              <button class="btn" @click="clickSave" v-if="isCookie && !isEdit">保存</button>
              <button class="btn" @click="clickConsult" v-else>立即咨询</button>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="menu-box">
      <div class="size36 size_b size_center text_color" style="margin-top: 100px;">丰富的解决方案，倾力满足行业所需</div>
      <div class="second_color size_center size20" style="margin-top: 20px;">合法、专业、安全、便捷的服务协助企业高效发展</div>
      <ul class="menu">
        <div class="b-slider" :style="{ left: getLeft }"></div>
        <li v-for="(item, index) in menuList" :key="index" :class="[$route.path === item.path && 'active']"
          @click="goRouter(item.path)">
          <img :src="$route.path === item.path ? item.selectIcon : item.icon" alt="">
          <div class="flex flex_j" style="margin-top: 10px;">
            <div class="name size16">{{ item.name }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
    <div class="three-box">
      <img class="earth" src="@/assets/images/earthway.png" alt="">
      <div class="size36 size_b size_center">不忘初心，专注电子签名与认证服务20载，重奖杯，更重口碑</div>
      <div class="second_color size_center" style="font-size: 20px; line-height: 26px; margin-top: 20px;">
        天威诚信作为国内电子认证领域的领头企业，拥有完善的产品开发与服务运营资质，优质高效的服务赢得了广大客户的高度认可。
      </div>
      <div class="item-box">
        <div class="box box-2000">
          <div class="slider"></div>
          <div class="text">
            <div class="p-box">
              <p>• 天威诚信成立</p>
              <p>• 经工业和信息化部许可成为全国PKI/CA企业</p>
              <p>• 与Verisign建立战略合作关系</p>
            </div>
            <div class="title mt10">2000 <span>年</span></div>
          </div>
        </div>
        <div class="box box-2010">
          <div class="slider active"></div>
          <div class="text">
            <div class="title mb10">2010 <span>年</span></div>
            <div class="p-box">
              <p>• 成为“北京电子商务服务平台重点企业”</p>
              <p>• iTrusESA、iTrusCA、iTrusUTS入选北京市自主创新产品目录</p>
              <p>• 承担国家发展和改革委员会信息安全专项——《基于电子签名的电子数据取证与证据管理系统研发和产业化项目》</p>
            </div>
          </div>
        </div>
        <div class="box box-2012">
          <div class="slider"></div>
          <div class="text">
            <div class="p-box">
              <p>• 参与起草的《信息安全技术——电子认证服务机构运营管理规范》经全国信息安全标准化技术委员会批准正式发布</p>
              <p>• 获国家密码管理局授予《商用密码产品销售许可证》</p>
              <p>• 经全国信息安全标准化技术委员会秘书处批准，成为“网站可信国家标准项目组成员单位”</p>
            </div>
            <div class="title mt10">2012 <span>年</span></div>
          </div>
        </div>
        <div class="box box-2015">
          <div class="slider active"></div>
          <div class="text">
            <div class="title mb10">2015 <span>年</span></div>
            <div class="p-box">
              <p>• 天威诚信子公司天诚安信加入“中关村四方现代服务产业技术创新战略联盟”</p>
              <p>• 开展中小微企业互联网金融服务和移动互联网+4.0试点研究</p>
              <p>• 天威诚信专利《降低主密钥破解和泄露危险的IBE数据加密系统及方法》获得授权</p>
              <p>• 获《信息系统集成及服务资质证书》</p>
            </div>
          </div>
        </div>
        <div class="box box-2016">
          <div class="slider"></div>
          <div class="text">
            <div class="p-box">
              <p>• 成为中关村国家自主创新示范区标准化试点单位</p>
              <p>• 被国家知识产权局授予“国家知识产权优势企业”称号</p>
            </div>
            <div class="title mt10">2016 <span>年</span></div>
          </div>
        </div>
        <div class="box box-2017">
          <div class="slider active"></div>
          <div class="text">
            <div class="title mb10">2017 <span>年</span></div>
            <div class="p-box">
              <p>• 取得《ISO/IEC27001信息安全管理体系认证证书》</p>
              <p>• 获得《电子政务电子认证服务许可》</p>
              <p>• 获得公安机关《信息系统安全等级保护备案证明(3级证书)》</p>
              <p>• 与武汉仲裁委员会签订战略合作，同年在全国率先上线网络仲裁服务平台</p>
            </div>
          </div>
        </div>
        <div class="box box-2018">
          <div class="slider"></div>
          <div class="text">
            <div class="p-box">
              <p>• 推出天威诚信电子认证云服务(PaaS)平台</p>
              <p>• 取得《知识产权管理体系认证》</p>
              <p>• 天威诚信在线法律服务平台正式上线，为企业客户提供线上业务的全流程电子证据固化、核验、存证服务，为多家互金平台提供在线电子合同取证服务
                承担的“服务器证书信任体系研究”通过国家密码管理局组织的课题验收</p>
            </div>
            <div class="title mt10">2018 <span>年</span></div>
          </div>
        </div>
        <div class="box box-2019">
          <div class="slider active"></div>
          <div class="text">
            <div class="title mb10">2019 <span>年</span></div>
            <div class="p-box">
              <p>• 正式通过国际Webtrust认证，天威诚信成为同时获得国内电子认证服务许可和国际CA审计认证的机构
                通过CMMI-DEV,V1.3成熟度三级认证</p>
              <p>• 与多家消费金融公司、网络小贷公司、担保机构正式启用网络仲裁服务、在线取证服务，并上线在线赋强公证业务</p>
              <p>• 承担的北京市科技计划课题“基于国产密码的云计算关键技术研究与应用示范(课题编号为：Z171100004417021)”通过验收</p>
              <p>• 承担了密码行业标准化委员会的密码行业标准制订任务（商用密码领域），项目名称为“TLS协议扩展字段技术规范”的研究任务</p>
            </div>
          </div>
        </div>
        <div class="box box-2020">
          <div class="slider"></div>
          <div class="text">
            <div class="p-box">
              <p>• 建立与华北、华东、西南、华南、华中地区仲裁机构的全面合作，与多家法院建立在线智能诉讼服务一体化解决方案，为广泛的企业客户提供优质、高效、高性价比的智能法律服务</p>
            </div>
            <div class="title mt10">2020 <span>年</span></div>
          </div>
        </div>
        <div class="box box-2021">
          <div class="slider active"></div>
          <div class="text">
            <div class="title mb10">2021 <span>年</span></div>
            <div class="p-box">
              <p>• 密码综合服务管理平台正式发布</p>
              <p>• 联合武汉仲裁委，助力企业仲裁案件量破万</p>
              <p>• 为国内最大汽车金融公司实现全流程业务线上化运营</p>
              <p>• 天威诚信WebTrust认证的两个根证书vTrus RSA/ECC
                Root正式通过了火狐Mozilla平台的预埋审核，在火狐开源密码模块NSS中发布，火狐全线产品及全球主流的linux系统均采用NSS密码模块</p>
            </div>
          </div>
        </div>
        <div class="box box-2022">
          <div class="slider"></div>
          <div class="text">
            <div class="p-box">
              <p>• 在密码行业标准化技术委员会组织下，牵头制定《公共可信证书管理SSL服务器证书运营与管理要求》，参与制定《公共可信证书管理规范SSL服务器证书服务管理审计要求》、《公共可信证书管理规范网络及系统安全要求》
                “诚信签”电子合同公有云平台正式上线</p>
              <p>• vTrus自主根SSL正式完成Google Root Strore预埋</p>
              <p>• 取得《ISO/IEC27701隐私管理体系认证证书》</p>
            </div>
            <div class="title mt10">2022 <span>年</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="four-box">
      <div class="title size_center size36 size_b" style="margin-top: 100px;">成功案例</div>
      <div class="second_color size_center size20 mt20">天威诚信一直是客户良好口碑，你值得拥有</div>
      <ul class="logo-box">
        <li class="flex flex_center" v-for="item in 45" :key="item">
          <img :src="getImgUrl(item)" alt="">
        </li>
      </ul>
    </div>
    <div class="five-box">
      <div class="five-cont">
        <img src="@/assets/images/hz-img.png" alt="">
        <div class="right-box">
          <div class="title">天威诚信期待与您合作</div>
          <div class="btn" @click="goTop">立即咨询</div>
        </div>
      </div>
    </div>

    <div class="footer-box size_center">
      Copyright©2023 深圳市天威诚信科技有限公司 版权所有 <a href="https://beian.miit.gov.cn" target="_bank">粤ICP备14027241号-1</a>
    </div>
  </div>
</template>

<script>
import HeaderView from './HeaderView.vue';
import CryptoJS from "crypto-js/crypto-js"
import { operatorId, source } from "@/utils/config"
import { setCookie, getCookie } from "@/utils/auto"
import SuccessTips from './SuccessTips.vue';
export default {
  components: {
    HeaderView,
    SuccessTips
  },
  computed: {
    getLeft() {
      const index = this.menuList.findIndex(v => v.path === this.$route.path);
      if (index === 0 && index !== undefined && index !== null) {
        return `90px`;
      } else {
        return `${(index * 240) + 90}px`;
      }
    }
  },
  data() {
    return {
      menuList: [
        {
          name: '企业级电子合同平台应用建设方案',
          icon: require("@/assets/images/icon.png"),
          selectIcon: require('@/assets/images/icon-select.png'),
          path: '/home'
        },
        {
          name: '车联网电子签名安全服务解决方案',
          icon: require("@/assets/images/icon1.png"),
          selectIcon: require('@/assets/images/icon1-select.png'),
          path: '/car-web'
        },
        {
          name: '电子招投标电子签名应用安全解决方案',
          icon: require("@/assets/images/icon2.png"),
          selectIcon: require('@/assets/images/icon2-select.png'),
          path: '/ele-bidding'
        },
        {
          name: '财务资金系统电子签名安全服务解决方案',
          icon: require("@/assets/images/icon3.png"),
          selectIcon: require('@/assets/images/icon3-select.png'),
          path: '/finance'
        },
        {
          name: '企业无纸化办公安全服务解决方案',
          icon: require("@/assets/images/icon4.png"),
          selectIcon: require('@/assets/images/icon4-select.png'),
          path: '/paperless'
        }
      ],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      form: {},
      isCookie: getCookie(),
      isEdit: false,
      showTips: false,
      tipMsg: '咨询成功',
      type: 'success', // 提示窗类型
      timer: null, // 时间类型
    }
  },
  mounted() {
    if (this.isCookie) {
      if (this.isJson()) {
        this.form = JSON.parse(localStorage.getItem('form'));
      }
      this.isEdit = true;
    }
  },
  methods: {
    isJson() {
      try {
        return JSON.parse(localStorage.getItem('form'));
      }
      catch (err) {
        return false;
      }
    },
    goRouter(path) {
      if (this.$route.path === path) return;
      if (path) {
        this.$router.push(path);
      }
    },
    getImgUrl(index) {
      try {
        return require(`@/assets/images/logo-${index}.png`);
      }
      catch (err) {
        return
      }
    },
    goTop() {
      document.documentElement.scrollTop = 0;
    },
    queryParse() {

      const form = {
        ...this.form,
        operatorId,
        source
      }

      const paramsKey = ['contacterContent', 'contacterMobile', 'contacterName', 'operatorId', 'organizationName', 'source'];

      let queryText = "";

      for (let key in paramsKey) {

        queryText += `${paramsKey[key]}=${form[paramsKey[key]] || ""}&`;

      }

      return queryText.slice(0, -1);

    },

    editQueryParse() {

      const form = {
        ...this.form,
        operatorId,
        source,
        id: getCookie()
      }

      const paramsKey = ['contacterContent', 'contacterMobile', 'contacterName', 'id', 'operatorId', 'organizationName', 'source'];

      let queryText = "";

      for (let key in paramsKey) {

        queryText += `${paramsKey[key]}=${form[paramsKey[key]] || ""}&`;

      }

      return queryText.slice(0, -1);

    },

    openTips(title, type = 'warning') {
      this.showTips = true;
      this.tipMsg = title;
      this.type = type;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showTips = false;
      }, 1000);
    },

    consultAdd(hexsha256) {

      // contacterContent=34&contacterMobile=123&contacterName=123&operatorId=e2dce3798ff811ed8fd2a4bb6d59b318&organizationName=深圳天威&source=szitrus 官网转入
      // operatorId, source
      const url = this.baseUrl + '/api/leads/import/?' + this.queryParse();
      fetch(url, {
        method: 'post',
        headers: {
          'Content-Signature': hexsha256
        }
      }).then(response => {
        response.json().then(res => {
          if (res.code === 0) {
            this.isEdit = true;
            setCookie(res.data);
            this.isCookie = getCookie();
            localStorage.setItem('form', JSON.stringify(this.form));
            this.openTips('咨询成功', 'success');
          }
        })
      })
    },

    consultEdit(hexsha256) {
      if (!this.form.contacterName) {
        this.openTips('姓名不能为空');
        return
      }
      if (!this.form.contacterMobile) {
        this.openTips('手机号码不能为空');
        return
      }
      if (!this.form.organizationName) {
        this.openTips('公司名称不能为空');
        return
      }
      if (!this.form.contacterContent) {
        this.openTips('咨询内容不能为空');
        return
      }
      const url = this.baseUrl + '/api/leads/update/?' + this.editQueryParse();
      fetch(url, {
        method: 'post',
        headers: {
          'Content-Signature': hexsha256
        }
      }).then(response => {
        response.json().then(res => {
          if (res.code === 0) {
            this.isEdit = true;
            setCookie(res.data);
            this.isCookie = getCookie();
            localStorage.setItem('form', JSON.stringify(this.form));
            this.openTips('保存成功', 'success');
          }
        })
      })
    },
    // 立即咨询
    clickConsult() {
      if (!this.form.contacterName) {
        this.openTips('姓名不能为空');
        return
      }
      if (!this.form.contacterMobile) {
        this.openTips('手机号码不能为空');
        return
      }
      if (!this.form.organizationName) {
        this.openTips('公司名称不能为空');
        return
      }
      if (!this.form.contacterContent) {
        this.openTips('咨询内容不能为空');
        return
      }
      const appSecret = 'aldjfopawenfaovi';
      const str = this.queryParse();
      //生成密钥
      var signHmacSHA256 = CryptoJS.HmacSHA256(str, appSecret);
      var hexsha256 = CryptoJS.enc.Hex.stringify(signHmacSHA256);
      this.consultAdd(hexsha256);
    },

    // 立即保存
    clickSave() {
      const appSecret = 'aldjfopawenfaovi';
      const str = this.editQueryParse();
      //生成密钥
      var signHmacSHA256 = CryptoJS.HmacSHA256(str, appSecret);
      var hexsha256 = CryptoJS.enc.Hex.stringify(signHmacSHA256);
      this.consultEdit(hexsha256);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.scss";

.banner-wrap {
  background-image: url("@/assets/images/banner-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 1200px;

  .banner-box {
    width: 1200px;
    height: 555px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin: auto;

    >img {
      display: block;
      margin-top: 130px;
      box-sizing: border-box;
    }

    .consult-box {
      width: 420px;
      height: 445px;
      background-color: #fff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 55px;

      .ipt {
        outline: none;
        box-sizing: border-box;
        padding: 0;
        border-radius: 4px;
        margin-top: 16px;
        width: 100%;
        padding: 0 16px;
        color: $textColor;
        font-size: 14px;

        &:first-child {
          margin-top: 24px;
        }

        &.readonly {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      >input {
        height: 42px;
      }

      >textarea {
        height: 90px;
        padding: 11px 16px !important;
      }

      >button {
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px;
        background-color: #D1191A;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 24px;
      }
    }
  }
}

.menu-box {
  .menu {
    width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-top: 80px;
    height: 120px;
    position: relative;

    .b-slider {
      width: 60px;
      height: 3px;
      background-color: #D12425;
      position: absolute;
      bottom: 0;
      transition: all 0.3s;
    }

    >li {
      text-align: center;
      cursor: pointer;

      >img {
        display: inline-block;
        size: 0;
      }

      .name {
        width: 144px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      }

      &.active {
        position: relative;
        color: #D12425;
        font-weight: bold;

        // &::after {
        //   display: block;
        //   content: "";
        //   position: absolute;
        //   width: 60px;
        //   height: 3px;
        //   border-radius: 1px;
        //   background-color: #D12425;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   bottom: 0;
        // }
      }
    }
  }
}

.container {
  margin-top: 30px;
}

.three-box {
  margin-top: 100px;
  height: 930px;
  min-width: 1200px;
  background-image: url("@/assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: 80px;
  overflow: hidden;

  .earth,
  .way {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }

  .item-box {
    width: 1200px;
    height: 525px;
    margin: auto;
    margin-top: 30px;
    box-sizing: border-box;
    border: 1px solid transparent;
    position: relative;

    >.box {
      position: absolute;
      display: flex;
      padding-left: 10px;

      .slider {
        width: 1px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, #d4d4d4, #d6d6d6 54%, rgba(255, 255, 255, 0.00));

        &.active {
          background: linear-gradient(180deg, #d4d4d4, #d6d6d6 76%, rgba(255, 255, 255, 0.00));
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #D1191A;
          left: 50%;
          transform: translateX(-50%);
          // z-index: 9999;
        }
      }

      .text {
        font-size: 14px;
        padding: 10px 0px;
        transition: all 0.3s;

        .p-box {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: 107px;

          >p {
            line-height: 22px;
            color: #1F1817;
          }
        }

        >.title {
          font-weight: bold;
          font-size: 24px;
          height: 29px;
          line-height: 29px;
          font-family: DIN, DIN-Bold;

          >span {
            font-size: 16px;
          }
        }
      }

      &-2000 {
        left: 40px;
        top: 42px;

        .slider {
          height: 220px;

          &::after {
            top: 220px;
          }
        }

        .text {
          margin-top: 18px;
        }
      }

      &-2010 {
        left: 14px;
        top: 360px;

        .slider {
          height: 290px;

          &::after {
            top: 0px;
          }
        }

        .text {
          margin-top: 76px;

          .p-box {
            -webkit-line-clamp: 8;
            width: 104px;
          }
        }
      }

      &-2012 {
        left: 127px;
        top: 264px;

        .slider {
          height: 170px;

          &::after {
            top: 170px;
          }
        }

        .text {
          margin-top: -20px;

          .p-box {
            -webkit-line-clamp: 6;
            width: 126px;
          }
        }
      }

      &-2015 {
        left: 235px;
        top: 453px;

        .slider {
          height: 220px;

          &::after {
            top: 0px;
          }
        }

        .text {
          width: 134px;
          margin-top: 16px;

          .p-box {
            -webkit-line-clamp: 7;
          }
        }
      }

      &-2016 {
        left: 351px;
        top: 229px;

        .slider {
          height: 230px;

          &::after {
            top: 230px;
          }
        }

        .text {
          margin-top: 12px;

          .p-box {
            width: 116px;
          }
        }
      }

      &-2017 {
        left: 462px;
        top: 454px;

        .slider {
          height: 330px;

          &::after {
            top: 0px;
          }
        }

        .text {
          margin-top: 16px;

          .p-box {
            -webkit-line-clamp: 8;
            width: 127px;
          }
        }
      }

      &-2018 {
        left: 580px;
        top: 121px;

        .slider {
          height: 320px;

          &::after {
            top: 320px;
          }
        }

        .text {
          margin-top: 48px;

          .p-box {
            -webkit-line-clamp: 9;
            width: 128px;

          }
        }
      }

      &-2019 {
        left: 694px;
        top: 420px;

        .slider {
          height: 230px;

          &::after {
            top: 0px;
          }
        }

        .text {
          margin-top: 16px;

          .p-box {
            -webkit-line-clamp: 8;
            width: 143px;
          }
        }
      }

      &-2020 {
        left: 801px;
        top: 84px;

        .slider {
          height: 310px;

          &::after {
            top: 310px;
          }
        }

        .text {
          margin-top: 35px;

          .p-box {
            width: 131px;
          }
        }
      }

      &-2021 {
        left: 918px;
        top: 356px;

        .slider {
          height: 210px;

          &::after {
            top: 0px;
          }
        }

        .text {
          margin-top: 16px;

          .p-box {
            width: 208px;
          }
        }
      }

      &-2022 {
        left: 1029px;
        top: 58px;

        .slider {
          height: 250px;

          &::after {
            top: 250px;
          }
        }

        .text {
          margin-top: 37px;

          .p-box {
            -webkit-line-clamp: 6;
            width: 124px;
          }
        }
      }

      &:hover {
        padding-left: 0;

        .slider {
          width: 0;

          &::after {
            background-color: #fff;
            width: 16px;
            height: 16px;
            border: 4px solid #D1191A;
            box-sizing: border-box;
            z-index: 9999;
          }
        }

        .text {
          box-sizing: border-box;
          background-color: #fff;
          border-radius: 10px;
          padding: 10px 0;
          z-index: 999;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);

          .p-box {
            margin: 0 10px;
            -webkit-line-clamp: inherit !important;
          }

          .title {
            margin-left: 10px;
          }
        }

        &.box-2000 {
          .text {
            margin-top: 130px;
          }
        }

        &.box-2010 {
          .text {
            margin-top: -200px;
          }
        }

        &.box-2012 {
          .slider {
            &::after {
              top: 168px;
            }
          }
        }

        &.box-2015 {
          .text {
            margin-top: -200px;
          }
        }

        &.box-2016 {
          .text {
            margin-top: 130px;
          }
        }

        &.box-2017 {
          .text {
            margin-top: -200px;
          }
        }

        &.box-2019 {
          .text {
            margin-top: -340px;
          }
        }

        &.box-2020 {
          .text {
            margin-top: 180px;
          }
        }

        &.box-2021 {
          .text {
            margin-top: -144px;
          }
        }
      }
    }
  }
}

.four-box {
  min-width: 1200px;

  .logo-box {
    width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-top: 60px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 24px;
    box-sizing: border-box;
    border-radius: 4px;

    >li {
      height: 75px;

      >img {
        display: block;
      }
    }
  }
}

.five-box {
  margin-top: 73px;
  height: 200px;
  min-width: 1200px;
  background-color: #E1251A;

  .five-cont {
    width: 1200px;
    margin: 0 auto;
    display: flex;

    >img {
      width: 699px;
      height: 100%;
      display: block;
    }

    .right-box {
      padding-left: 21px;

      .title {
        font-size: 32px;
        font-weight: bold;
        color: #fff;
        height: 42px;
        line-height: 42px;
        margin-top: 42px;
      }

      .btn {
        margin-left: 78px;
        width: 164px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border: 1px solid #fff;
        border-radius: 4px;
        font-size: 16px;
        color: #fff;
        margin-top: 25px;
        cursor: pointer;
      }
    }
  }
}

.footer-box {
  min-width: 1200px;
  background-color: #3A3A3A;
  height: 58px;
  line-height: 58px;
  font-size: 14px;
  color: #fff;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;

  a {
    color: #fff;

    &:hover {
      color: red;
    }
  }
}
</style>