import Vue from 'vue'
import VueRouter from 'vue-router';
import LayoutView from "@/components/LayoutView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LayoutView,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import("@/views/HomeView")
      },
      {
        path: '/car-web',
        name: 'car-web',
        component: () => import("@/views/CarWeb")
      },
      {
        path: '/ele-bidding',
        name: 'ele-bidding',
        component: () => import("@/views/ElectronicBidding")
      },
      {
        path: '/finance',
        name: 'finance',
        component: () => import("@/views/FinanceView")
      },
      {
        path: '/paperless',
        name: 'paperless',
        component: () => import("@/views/PaperlessView")
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
