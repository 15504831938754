<template>
  <div>
    <header class="header flex flex_b">
      <div class="left-box flex flex_a">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="right-box flex flex_a">
        <span class="text_gray size14">询价？提需求？合作协商？立即拨打</span>
        <span class="red_color ml8 size24 size_b"> 0755-26745995</span>
      </div>
    </header>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.header {
  width: 1200px;
  box-sizing: border-box;
  height: 72px;
  margin: auto;

  .left-box {

    >img {
      display: block;
      height: 52px;
      width: 290px;
    }
  }

  .right-box {}
}
</style>